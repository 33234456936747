import { formatInTimeZone } from 'date-fns-tz'
import { ko } from 'date-fns/locale/ko'

/**
 * 값이 비어있는지에 대한 확인.
 *
 * @param value 값.
 * @returns 값이 비어있는지 확인.
 */
export function isEmpty(value: unknown): boolean {
  if (value === null) return true
  if (typeof value === 'undefined') return true
  if (typeof value === 'string' && value === '') return true
  if (typeof value === 'string' && value.trim().length === 0) return true
  if (typeof value === 'string' && value === 'undefined') return true
  if (typeof value === 'string' && value === 'null') return true
  if (Array.isArray(value) && value.length < 1) return true
  if (
    typeof value === 'object' &&
    value.constructor.name === 'Object' &&
    Object.keys(value).length < 1 &&
    Object.getOwnPropertyNames(value).length < 1
  ) {
    return true
  }
  return (
    typeof value === 'object' &&
    value.constructor.name === 'String' &&
    Object.keys(value).length < 1
  )
}

/**
 * 올바른 이메일 형식인지 확인.
 *
 * @param email 이메일.
 * @returns 이메일 형식이 올바른지에 대한 확인.
 */
export function isValidEmail(email: string): boolean {
  const regExp =
    /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
  return regExp.test(email)
}

/**
 * 올바른 폰 번호 형식인지 확인.
 *
 * @param mobileNum 폰 번호.
 * @returns 폰 번호 형식이 올바른지 확인.
 */
export function isValidMobileNum(mobileNum?: string): boolean {
  if (!mobileNum) return false

  const regExp = /^[0-9]{3}([- ]?)+[0-9]{3,4}([- ]?)+[0-9]{4}$/
  return regExp.test(mobileNum)
}

/**
 * 날짜 formatting.
 *
 * @param timestamp 날짜.
 * @param format 변경할 format.
 *
 * @returns Formatting 된 날짜.
 */
export function getFormatDate(timestamp: number, format: string): string {
  const koreaTimeZone = 'Asia/Seoul'
  const date = new Date(timestamp)

  return formatInTimeZone(date, koreaTimeZone, format, { locale: ko })
}

/**
 * 핸드폰 번호 formatting.
 *
 * @param phoneNum 핸드폰 번호.
 * @returns Formatting 된 폰 번호.
 */
export function getFormattedPhoneNum(phoneNum?: string): string | null {
  if (!phoneNum) return null
  return phoneNum.replace(/^(\d{3})(\d{4})(\d{4})$/, `$1-$2-$3`)
}

/**
 * XSS (Cross-site scripting) 방어.
 *
 * @param str 문자.
 * @param level (0 = 전체 검사).
 * @returns 치환된 문자.
 */
export function XSSCheck(str: string, level?: number) {
  if (!level || level === 0) {
    str = str.replace(/\<|\>|\"|\'|\%|\;|\(|\)|\&|\+|\-/g, '')
  } else if (level === 1) {
    str = str.replace(/\</g, '&lt;')

    str = str.replace(/\>/g, '&gt;')
  }
  return str
}

/**
 * 쿠키 존재 유무 확인.
 *
 * @param name 쿠키 이름.
 * @returns 쿠키 존재 유무.
 */
export function isCookieExist(name: string): boolean {
  const regex = new RegExp(`^(.*;)?\\s*${name}\\s*=\\s*[^;]+(.*)?$`)
  return document.cookie.match(regex) != null
}

/**
 * 쿠키 설정하기.
 *
 * @param cookieName 쿠키 이름.
 * @param cookieValue 쿠키 값.
 * @param cookieExpire 쿠키 만료일.
 * @param cookieSecure 쿠키 보안 설정.
 */
export function setCookie(
  cookieName: string,
  cookieValue: string,
  cookieExpire: Date,
  cookieSecure?: boolean,
): void {
  let cookieText = cookieName + '=' + cookieValue
  cookieText += cookieExpire ? '; EXPIRES=' + cookieExpire.toUTCString() : ''
  cookieText += '; PATH=/'
  cookieText += `; DOMAIN=${getDomainName(window.location.hostname)}`
  cookieText += cookieSecure ? '; SECURE' : ''
  document.cookie = cookieText + '; SameSite=Lax'
}

/**
 * 쿠키 가져오기.
 *
 * @param cookieName 쿠키 이름.
 * @returns 쿠키 값.
 */
export function getCookie(cookieName: string): string | null {
  let cookieValue: string | null = null
  if (document.cookie) {
    const array = document.cookie.split(cookieName + '=')
    if (array.length >= 2) {
      const arraySub = array[1].split(';')
      cookieValue = arraySub[0]
    }
  }
  return cookieValue
}

/**
 * 쿠키 삭제.
 *
 * @param name 쿠키 이름.
 * @param path 쿠키 경로.
 * @param domain 쿠키 도메인.
 */
export function deleteCookie(name: string, path?: string, domain?: string): void {
  if (isCookieExist(name)) {
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain
        ? ';domain=' + domain
        : `;domain=.${getDomainName(window.location.hostname)}`) +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT'
  }
}

/**
 * 도메인 네임 구하기.
 *
 * @param hostName 현재의 호스트 네임.
 * @returns ex) www.imsc21.com -> imsc21.com
 */
export function getDomainName(hostName: string) {
  return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1)
}

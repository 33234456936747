import { httpGet, httpPost, httpDelete } from '@/apis/http'

class AuthService {
  /**
   * 로그인.
   *
   * @param user 사용자 정보.
   * @returns Promise<T>.
   */
  async signin<T>(id: string, password: string) {
    const formData = new FormData()
    formData.append('memberId', id)
    formData.append('password', password)

    return httpPost<T>('/api/login', formData)
  }

  /**
   * 간편 로그인.
   *
   * @param socialData 간편 로그인 관련 정보.
   * @returns Promise<T>.
   */
  async signInWithSocial<T>(socialData: { [key: string]: string }) {
    return httpPost<T>('/api/social/login', socialData)
  }

  /**
   * Captcha 불러오기.
   *
   * @returns Promise<T>.
   */
  async getCaptcha<T>() {
    return httpPost<T>('/api/getCaptcha')
  }

  /**
   * Captcha 확인.
   *
   * @param key
   * @param value
   * @returns Promise<T>.
   */
  async checkCaptcha<T>(key: string, value: string) {
    const formData = new FormData()
    formData.append('captchaKey', key)
    formData.append('captchaValue', value)

    return httpPost<T>('/api/checkChptcha', formData)
  }

  /**
   * ID 중복 유무 확인.
   *
   * @param userId 사용자 ID.
   * @returns Promise<T>.
   */
  async checkIdDuplicated<T>(userId: string) {
    return httpGet<T>('/api/checkId', { userId })
  }

  /**
   * 회원 가입.
   *
   * @param json 회원가입에 필요한 데이터들.
   * @returns Promise<T>.
   */
  async signup<T>(json: { [key: string]: string }) {
    const formData = new URLSearchParams()
    formData.append('userId', json.userId)
    formData.append('name', json.name)
    formData.append('mobile', json.mobile)
    formData.append('password', json.password)
    formData.append('childrenList', json.childrenList)
    formData.append('enrollType', json.enrollType)
    formData.append('email', json.email)

    return httpPost<T>('/api/signup', formData)
  }

  /**
   * 아이디 찾기.
   *
   * @param userName 사용자 이름.
   * @param userEmail 사용자 이메일.
   * @returns Promise<T>.
   */
  async findMyId<T>(userName: string, userEmail: string) {
    return httpGet<T>('/api/findId', { name: userName, phone: userEmail })
  }

  /**
   * 비밀번호 초기화 메일 요청.
   *
   * @param userId 사용자 ID.
   * @param userName 사용자 이름.
   * @returns Promise<T>.
   */
  async findMyPw<T>(userId: string, userName: string) {
    return httpGet<T>('/api/findPw', { userId, name: userName })
  }

  /**
   * 비밀번호 변경.
   *
   * @param password 비밀번호.
   * @param urlCode 비밀번호 찾기 code.
   * @returns Promise<T>.
   */
  async changePassword<T>(password: string, urlCode: string) {
    const formData = new FormData()
    formData.append('password', password)
    formData.append('urlCode', urlCode)

    return httpPost<T>('/api/change/password', formData)
  }

  /**
   * 인증 상태 확인.
   *
   * @returns Promise<T>.
   */
  async checkAuthStatus<T>() {
    return httpGet<T>('/api/auth')
  }

  /**
   * SMS 인증 발송.
   *
   * @param name 학생 이름.
   * @param mobile 학부모 전화번호.
   * @returns Promise<T>.
   */
  async getAuthSMSToken<T>(name: string, mobile: string) {
    const formData = new FormData()
    formData.append('studentName', name)
    formData.append('parentsMobile', mobile)

    return httpPost<T>('/api/auth/send', formData)
  }

  /**
   * 로그 아웃
   *
   * @returns Promise<T>.
   */
  async logout<T>() {
    return httpPost<T>('/api/logout')
  }

  /**
   * 회원 탈퇴.
   *
   * @param userId 계정 id.
   * @param password 계정 비밀번호.
   * @returns Promise<T>.
   */
  async withDraw<T>(userId: string, password: string) {
    const formData = new FormData()
    formData.append('userId', userId)
    formData.append('password', password)

    return httpPost<T>('/api/withdrawalUser', formData)
  }

  /**
   * 회원 탈퇴 (간편 로그인).
   *
   * @param enrollType 계정 타입.
   * @returns Promise<T>.
   */
  async withDrawSocial<T>(enrollType: 'email' | 'naver' | 'kakao' | 'apple') {
    return httpDelete<T>('/api/social/revoke', { enrollType })
  }

  /**
   * 자동 로그인.
   *
   * @returns Promise<T>.
   */
  async silentRefresh<T>() {
    return httpPost<T>('/api/token/refresh')
  }
}

export default new AuthService()

import React from 'react'

interface imscType {
  isLoggedIn: boolean
  isShowSideMenu: boolean
  mainCategory: string
  subCategory: string
  sideMenuIdx: number
  isAuth: boolean
  setIsLoggedIn: (value: boolean) => void
  setIsShowSideMenu: (value: boolean) => void
  setMainCategory: (value: string) => void
  setSubCategory: (value: string) => void
  setSideMenuIdx: (value: number) => void
  setIsAuth: (value: boolean) => void
}

export const iMSCContext = React.createContext<imscType>({
  isLoggedIn: false,
  isShowSideMenu: false,
  mainCategory: 'default',
  subCategory: 'why_msc',
  sideMenuIdx: 0,
  isAuth: false,
  setIsLoggedIn: () => {},
  setIsShowSideMenu: () => {},
  setMainCategory: () => {},
  setSubCategory: () => {},
  setSideMenuIdx: () => {},
  setIsAuth: () => {},
})
